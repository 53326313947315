@import url('https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap');


/* MIXINS */
@mixin fullwidth {
  width: 100vw;
  height: 100vh;
}
@mixin column {
  display: flex;
  flex-direction: column;
}
@mixin row {
  display: flex;
  flex-direction: row;
}
@mixin box  {
  box-sizing: border-box;
}

@mixin clearbtn {
  background: none;
  outline: none;
  border: none;
}


/* COLOURS */
$blue-base: #0073A7;
$yellow-base: #FFEF00;
$lightish-blue: #D1E7F2;



/* GENERAL STUFF */
html, body {
    height: auto;
}
body {
  margin: 0;
  font-family: 'Raleway', ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  @include box;
}
p, strong {
  color: black;
}
.margbot20{
  margin-bottom: 20px;
}
.margtop20{
  margin-top: 20px;
}
.margtop50{
  margin-top: 50px;
}
.extramarg {
  margin: 0 10px 0 10px;
  text-decoration: none;
  font-weight: bold;
  color: $blue-base;
}
.minimarg {
  margin-bottom: 6px;
}
.centeredcolumn {
  @include column;
  justify-content: center;
  align-items: center;
}
.centeredrow {
  @include row;
  justify-content: center;
  align-items: center;
}
.centeredtxt {
  text-align: center;
}
.navshadow {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
}
.navshadow2 {
  -webkit-filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .25));
  filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .25));
}
.shadow {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
}
.opac20 {
  opacity: 0.2;
}
button {
  cursor: pointer;
}
.textlink {
  text-decoration: underline;
  color: $blue-base;
  font-weight: bold;
  font-size: 12px;
}
.higher {
  z-index: 99;
  position: absolute;
  bottom: 150px;
  right: 150px;
  background: white;
}




/* ANT INPUT */
.ant-input {
  font-weight: bold;
}
.ant-input-lg {
  font-size: 14px;
}
.ant-input:focus,.ant-input:hover {
  border: 2px solid #0072A9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}
.ant-input:-webkit-autofill {
  background-color: rgb(0,0,0) !important;
  -webkit-box-shadow: inset 0 0 0px 9999px white;
  box-shadow: inset 0 0 0px 9999px white;
}
.inputter {
  margin-top: 10px;
  padding: 24px 15px 22px;
}



/* MAP/LIST */
.map_canvas {
  width: 100vw;
  background-color: #FAFAFA;
}
.map {
  width: 100%;
  height: 100vh;
  margin-top: -172px;
}
.list_canvas {
  background-color: darkgrey;
}
.overlay {
  @include fullwidth;
  @include column;
  @include box;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}
.useroverlay {
  @include box;
  width: 85vw;
  max-width: 400px;
  background-color: white;
  padding: 50px 25px 50px 25px;
  z-index: 99;
  border-radius: 10px;
  margin-top: -50px;
  max-height: 82vh;
  overflow: scroll;
}


/* NAV */
.top_navigation {
  @include box;
  @include row;
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  width: 100%;
  height: 110px;
  padding: 0px 30px 0 30px;
  justify-content: space-between;
  align-items: flex-end;
  top: 0;
  left: 0;
  margin: 0;
}
.toggleswitch {
  @include row;
  transition: all 0.01s ease-in-out;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  border-radius: 26px;
  margin: 0 0 6px 0;
}
.toggleswitch_part_right {
  @include box;
  @include row;
  background-color: white;
  width: 56px;
  height: 52px;
  border-radius: 26px 0px 0px 26px;
  padding-right: 13px;
  border-right: 1px #CCCCCC dashed;
  align-items: center;
  justify-content: flex-end;
}
.toggleswitch_part_left {
  @include box;
  @include row;
  background-color: white;
  width: 56px;
  height: 52px;
  border-radius: 0px 26px 26px 0px;
  padding: 8px 0 0 13px;
  align-items: center;
  justify-content: flex-start;
}
.toggleswitch:active {
  transform: scale(0.99);
}



/* BTN SPECIFICS */
.loginbtn {
  color: black;
  width: 100%;
  max-width: 400px;
  padding: 18px 0 16px;
  height: auto;
  background: $yellow-base;
  border: none;
  font-weight: bold;
  margin: 30px 0 0 0;
}
.logoutbtn {
  color: black;
  width: 100%;
  max-width: 400px;
  padding: 18px 0 16px;
  height: auto;
  background: $yellow-base;
  border: none;
  font-weight: bold;
  margin: 0;
}

.ty4signup {
  @include box;
  padding: 0 6vw;
  text-align: center;
}
.ty4signup .confirmer {
  margin: 0px 0 30px 0;
}
.ty4signup strong {
  margin: 0px 0 10px 0;
}
.newbtn_navigation_container {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  height: 64px;
  @include box;
  @include row;
  justify-content: flex-end;
  padding: 0px 30px 0 30px;
  top: 70vh;
  z-index: 100;
}
.newbtn_navigation {
  @include clearbtn;
  border-radius: 5px;
  padding: 18px 18px 10px 18px;
  background: white;
  border: none;
  border-radius: 50%;
  height: 64px;
  width: 64px;
}

.errormsg {
  @include box;
  padding: 15px;
  margin: 10px 0 20px 0;
  width: 275px;
  background-color: $lightish-blue;
}
.errormsg p {
  font-weight: 700;
  color:black;
  margin: 0;
}
.nomrg {
  width: 100%;
  margin: 20px 0 0 0;
}

.uploadedtotal {
  @include column;
  justify-content: flex-end;
  align-items: center;
}
.deletebtn {
  @include clearbtn;
  z-index: 2;
  padding: 18px 18px 10px 18px;
  background: white;
  border: none;
  border-radius: 50%;
  margin-top: -36px;
  height: 56px;
  width: 56px;
}

.closebtn_navigation_container {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  height: 70px;
  @include box;
  @include row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0px 20px 0 20px;
  top: 0;
  z-index: 100;
}
.closebtn_navigation_container button {
  @include clearbtn;
  z-index: 10;
}



/* PROFILE */
.profilepage_total {
  @include column;
  @include box;
  width: 100%;
  min-height: 100vh;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: -70px 0 0 0;
}

.profilepage_top {
  @include column;
  @include box;
  width: 100%;
  overflow-x: scroll;
  justify-content: flex-start;
  align-items: center;
  padding: 100px 50px 0 50px;
  background-color: white;
}
.profilepage_top p:nth-child(2) {
  font-weight: 700;
  margin: 30px 0 6px 0;
}
.profilepage_top_loader {
  height: 100%;
  @include column;
  justify-content: center;
  align-items: center;
}
.profilepage_middle {
  width: 100%;
}
.profilepage_middle_btn {
  @include clearbtn;
  margin: 0 0 -6px 0;
  padding: 12px 12px 6px 12px;
  background: white;
  border: none;
  border-radius: 50%;
  z-index: 99;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: $blue-base;
  font-weight: 700;
  text-decoration: underline;
}
.ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.profilepage_useritems {
  @include box;
  width: 100%;
  padding: 50px 0;
  border-top: 1px dashed #CCCCCC;
}
.profilepage_useritems p {
  text-align: center;
}
.noneyet {
  color:#999999;
  margin: 0;
}
.ant-collapse-item {
  @include column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.profilepage_bottom {
  @include column;
  @include box;
  flex: 1;
  min-height: 20vh;
  width: 100%;
  overflow-x: scroll;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
  background-color: #F5F5F5;
}



/* ABOUT */
.aboutpage_total {
  @include column;
  @include box;
  width: 100%;
  min-height: 100vh;
  overflow-x: scroll;
  justify-content: flex-start;
  align-items: center;
  padding: 100px 20px 25vh 20px;
  background: url(./imgs/Rikworld_navbg.png) repeat;
  margin: -70px 0 0 0;
}
.aboutpage_txt {
  text-align: center;
  background-color: #00577E;
  @include box;
  padding: 35px 10vw 35px 10vw;
  margin: 50px 0 0 0;
  max-width: 900px;
}
.aboutpage_txt h2 {
  color: $yellow-base;
  margin: 0 0 15px 0;
  font-size: 24px;
  font-weight: 700;
}
.aboutpage_txt a {
  color: inherit;
  text-decoration: underline;
}
.aboutpage_txt p, .aboutpage_txt strong {
  color: white;
}
.nowyouseeme {
  display: block;
}
.nowyoudont {
  display: none;
}
.msgview {
  padding-top: 200px;
}
.msgview input {
  margin-top: 20px;
  max-width: 600px;
}
.stickers_btn {
  width: 265px;
  padding: 18px 0 16px;
  height: auto;
  background: $yellow-base;
  max-width: 400px;
  border: none;
  font-weight: bold;
  margin: 30px 0 0 0;
  border-radius: 4px;
}
.stickers_btn a {
  color: black;
  font-size: 16px;
}

/* NEWITEM */
.newitem_total {
  @include column;
  @include box;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 12vh 50px 50px 50px;
  margin-top: -70px;
}
.newitem_img_infos {
  @include column;
  @include box;
  width: 100%;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.newitem_img_infos input {
  margin: 15px 0;
}
.newitem_img_infos .ant-btn-lg {
  @include clearbtn;
  width: 100%;
  max-width: 400px;
  padding: 20px 15px;
  margin: 30px 0 30px 0;
  height: auto;
}
.newitem_infos {
  @include column;
  width: 100%;
  flex:1;
}
.pasw_error {
  font-weight: bold;
  margin: 10px 0 0 0;
}
.pasw_error p {
  color: $blue-base;
}
.conditions {
  @include box;
  font-size: 10px;
  margin-top: 15px;
  padding: 0 20px;
  text-align: center;
}
.img_upload_total {
  @include column;
  @include box;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 175px;
  border: 4px solid $lightish-blue;
  border-radius: 4px;
  margin: 0;
  padding: 8px;
}
.img_upload_total_w_img {
  @include column;
  @include box;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: auto;
  border: 4px solid $lightish-blue;
  border-radius: 4px;
  margin: 0;
  padding: 8px;
}
.img_upload_total_canvas {
  @include column;
  @include box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #E8F3F9;
  image-orientation: none;
  image-orientation: from-image;
}
.img_upload_total_canvas img {
  width: 100%;
}
.img_upload_btn {
  @include clearbtn;
  z-index: 2;
  padding: 18px 18px 10px 18px;
  background: white;
  border: none;
  border-radius: 50%;
}



/* INNERBOX */
.innerBox {
  width: 300px;
  max-height: 350px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.innerBox_top {
  @include row;
  @include box;
  padding: 20px 35px 15px 20px;
  align-items: center;
  justify-content: flex-start;
}
.innerBox_avatar {
  @include row;
  align-items: center;
  justify-content: flex-start;
}

.innerBox_avatar p {
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  margin: 0 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.innerBox_below p {
  width: 100%;
  margin-bottom: 10px;
}
.innerBox_below h5 {
  font-weight: 700;
  color: #CDCDCD;
  margin: 0;

}

.innerBox_top h5 {
  font-weight: normal;
  margin: 0 0 0 10px;
  font-size: 14px;
  line-height: 1.5em;
  color: #616161;
  text-align: right;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.innerBox_location {
  @include row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}
.innerBox_location_latlong {
  margin: 0 0 0 6px;
}
.innerBox_location_latlong p {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: $blue-base;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.innerBox_img {
  width: 100%;
  background: #F0F0F0;
}
.innerBox_img_noimg {
  padding: 30px;
  text-align: center;
  opacity: 0.25;
  font-weight: bold;
  font-size: 0.8em;
}
.innerBox_img_landsc {
  width: 100%;
}
.innerBox_img_portrait {
  width: 75%;
}
.innerBox_below {
  @include column;
  @include box;
  padding: 20px;
  align-items: flex-start;
  justify-content: flex-start;
}
.mapitem .innerBox_location {
  @include box;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 0 0 10px 10px;
}
.mapitem .innerBox_location {
  align-items: flex-start;
}
.mapitem .innerBox_location_latlong p {
  font-size: 0.85em;
  line-height: 1.5em;
  font-weight: normal;
  text-decoration: none;
  color: #878787;
  white-space: nowrap;
  overflow: show;
  text-overflow: unset;
  max-width: 100%;
  margin: 0;
}
.mapitem .innerBox_location svg {
  margin: 3px 4px 0 0px;
}

.listitem .innerBox_location_total {
  @include box;
  @include column;
  padding: 15px 20px 20px;
  background: #f0f5f7;
}
.listitem .innerBox_location {
  @include box;
  padding: 10px 20px;
  margin: 0;
  justify-content: center;
}
.listitem .innerBox_location:hover, .listitem .innerBox_location a:hover, .listitem .innerBox_location svg:hover {
  cursor: pointer;
}
.listitem .innerBox_below {
  @include column;
  @include box;
  padding: 0 20px 10px 20px;
  align-items: flex-start;
  justify-content: flex-start;
} 



/* LOCATION DRAGGER */
.locationdragger_total {
  @include column;
  justify-content: center;
  align-items: flex-start;
}
.pointer {
  width: 20px;
  height: 20px;
  margin: -6px 0 0 -3px;
  border-radius: 50%;
  left: 50vw;
  top: 38vh;
  background: white;
  border: 4px solid $yellow-base;
  z-index: 99;
  position: absolute;  
}
.locatie_aanduiden_btn {
  @include row;
  justify-content: center;
  align-items: center;
  width: 200px;
}
.locatie_aanduiden_btn p {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: $blue-base;
  margin: 0 0 0 6px;
}
.locatie_aanduiden_btn_latlong {
  margin: 0 0 0 6px;
}
.locatie_aanduiden_btn_latlong p {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: #56c77f;
  margin: 0;
}
.adressfinder {
  @include box;
  @include row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 25px 30px 0 30px;
  position: absolute;
  top: 20px;
  z-index: 99;
  width: 80%;
}
.adressfinder button {
  @include clearbtn;
  @include box;
  color: black;
  padding: 10px 16px;
  height: auto;
  background: $yellow-base;
  border: none;
  font-weight: bold;
  margin: 0;
  max-width: 400px;
}
.locationdragger_bottom {
  @include column;
  @include box;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
  width: 100%;
  margin-top: -15px;
  z-index: 99;
}
.locationdragger_bottom p {
  font-size: 14px;
  color: #CDCDCD;
  text-align: center;
}
.react-geocoder {
  width: 100%;
  margin-bottom: 0px;
}
.react-geocoder-results {
  @include box;
  padding: 8px 10px;
  background-color: white;
}
.react-geocoder input {
  @include box;
  min-height: 40px;
  width: 100%;
  padding: 14px 15px 10px 40px;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  line-height: 1.5;
  background: url(./imgs/search.png) no-repeat 10px 10px white;
  background-size: 25px 25px;
}

.react-geocoder input:focus {
  border: 2px solid $blue-base;
  outline: none;
}



/* LIST SPECIFICS*/
.list {
  width: 100%;
  @include box;
  @include column;
  padding: 150px 10vw 50px 10vw;
  justify-content: flex-start;
  align-items: center;
  margin-top: -172px;
}
.listitem {
  // overflow: hidden;
  width: 100%;
  max-width: 370px;
  break-inside: avoid;
  grid-column-start: auto;
  @include column;
  @include box;
  border-bottom: 1px solid #E2E2E2;
  background-color: white;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.10);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.10);
  margin: 5px 5px 15px 5px;
}

.avatarimg {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.useravatar {
  width: 30px;
  height: 30px;
}
.profilepage_avatar {
  width: 100px;
  height: 100px;
}


.avatar_total {
  width: 100%;
  @include column;
  @include box;
  justify-content: center;
  align-items: center;
  padding: 0 0 15px 0;
}

.avatar_edit_btn {
  margin: -34px 0 0 60px;
  padding: 12px 12px 5px 12px;
  background: white;
  border: none;
  border-radius: 50%;
}
.avatar_rotate_btn {
  margin: 0;
  padding: 12px 12px 5px 12px;
  background: white;
  border: none;
  border-radius: 50%;
}
.avatar_cc_btn {
  @include clearbtn;
  margin: 0 5px 40px;
  width: 48px;
  height: 48px;
  padding: 0;
  background: $yellow-base;
  border: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.zoomslider {
  margin: 30px 0 20px;
  width: 200px;
}
.avatar {
  @include box;
  margin: 0;
  padding: 4px 4px 4px 4px;
  border: 4px solid #D1E7F1;
  border-radius: 50%;
}

.avatar_total .avatar img {
  width: 72px;
  border-radius: 50%;
}

.profile_editor .avatar img {
  width: 100px;
  border-radius: 50%;
}
// .profile_editor .avatar_edit_btn {
//   margin: 42px 0 0 40px;
// }

.editor-canvas {
  width: 200px;
  height: 200px;
}

.smallio {
  width: 100px;
}



/* SVG stuff */
.st0{fill:#125C7E;}
.st1{fill:#007AA7;}
.st2{fill:#B9AC07;}
.st3{fill:#FFF200;}
.st4{fill:#9C1421;}
.st5{fill:#DD8E62;}
.st6{fill:#EC9C6A;}
.st7{fill:#BE1E2D;}
.st8{fill:#C01F2E;}
.st9{fill:#C85248;}
.st10{fill:#4B3203;}
.st11{fill:#3F2800;}
.st12{fill:none;}
.st13{fill:#E6E7E8;}
.st14{fill:#E2D02A;}
.st15{fill:#FDE729;}
.st16{fill:#ECDB14;}
.st17{fill:#E8D519;}
.st18{fill:#EDD91B;}
.st19{fill:#FAEF5F;}
.st20{fill:#F6E000;}
.st21{fill:#FFF450;}
.st22{fill:#E4D344;}



.marker-btn {
  @include clearbtn;
  transition: all 0.02s linear;
  width: inherit;
  padding: 0;
  margin: 0;
  -webkit-filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .25));
  filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .25));
}
.mapboxgl-marker {
  z-index: 98;

}


.markpos18 {
  width: 18px;
  margin: -10px 0 0 -9px;
}

.markpos22 {
  width: 22px;
  margin: -15px 0 0 -11px;
}

.markpos26 {
  width: 26px;
  margin: -18px 0 0 -13px;
}

.markpos36 {
  width: 36px;
  margin: -30px 0 0 -18px;
}

.markpos44 {
  width: 44px;
  margin: -38px 0 0 -22px;
}

.markpos56 {
  width: 56px;
  margin: -50px 0 0 -28px;
}

.markpos68 {
  width: 68px;
  margin: -64px 0 0 -34px;
}

.markpos80 {
  width: 80px;
  margin: -72px 0 0 -40px;
}



.mapboxgl-popup {
  top:-50px;
  z-index: 99;
}

.mapboxgl-popup-content {
  padding: 0;
  border-radius: 10px;
}

.mapboxgl-popup-close-button {
  font-size: 2em;
  width: 30px;
  height: 30px;
  line-height: normal;
}



.mapboxgl-popup-close-button button {
  width: 30px;
  height: 30px;
}



/*

@media (min-width: 1281px) {
  .newbtn_navigation {
    top: 80vh;
    left: 92vw;
  }
 
  .closebtn_navigation {
    left: 95vw;
  }

  .newitem_total {
  padding: 10vh 7vw 50px 5vw;
  }

  .newitem_img_infos {
    @include row;
    @include box;
    width: 100%;
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .newitem_img_infos .ant-btn-lg {
    @include clearbtn;
    width: 100%;
    max-width: 400px;
    padding: 20px 15px;
    margin: 0px 0 30px 0;
    height: auto;
  }
  .newitem_infos {
    flex: 1;
    padding: 0 0 0 35px;
  }
  
}

@media (min-height: 468px) and (max-height: 570px) and (orientation: portrait) {
     
  .newbtn_navigation {
    top: 400px;
  }
  
}

@media (min-height: 570px) and (max-height: 768px) and (orientation: portrait) {
     
  .newbtn_navigation {
    top: 500px;
  }
  
}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
     
  .closebtn_navigation {
    left: 93%;
  }
  
  .newbtn_navigation {
    top: 800px;
    padding-left: 88%;
  }
  
}



@media (min-width: 481px) and (max-width: 820px) and (orientation: landscape) {
  
  .newbtn_navigation {
    top: 270px;
    padding-left: 88%;
  }
   
  .closebtn_navigation {
    left: 93%;
  }


}
*/



/* NEW RESPONSIVENESS */

@media screen and ( min-height: 1000px ), ( min-width: 700px ) {
  .aboutpage_total {
    // @include row;
  }

  .newbtn_navigation_container {
    top: 78vh;
  }
  .cond_new {
    align-self: flex-end;
    text-align: right;
    padding-right: 0;
  }
}

@media screen and ( min-height: 699px ) {
  .innerBox {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    max-height: 400px;
  }
}

@media screen and ( min-width: 700px ) {
  .newitem_total {
    padding: 12vh 10vw 50px 10vw;
  }
  .newitem_infos {
    padding: 0 0 0 50px;
    align-items: flex-start;
  }
  .newitem_img_infos {
    @include row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .newitem_img_infos .ant-btn-lg {
    margin: 0px 0 30px 0;
  }
  .loginbtn {
    align-self: flex-end;
  }
  // .list {
  //   display: block;
  //   column-count: 2;
  //   column-gap: 20px;
  //   align-items: flex-start;
  // }
}

@media screen and ( min-width: 1000px ) {
  // .list {
  //   column-count: 3;
  //   padding: 150px 15vw 50px 15vw;
  // }
}
@media screen and ( min-width: 1500px ) {
  .list {
    column-count: 4;
    padding: 150px 15vw 50px 15vw;
  }
}






.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}


.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content, .widthy {
  width: 100%;
}